<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-5xl"
      @close="$emit('close')"
      @opened="onOpened"
    >
      <base-card
        :title="`Detail ${label}`"
        :description="`Berdasarkan data ${label} yang telah dibuat`"
        :with-style="false"
      >
        <div class="border-t pt-5">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6 sm:pb-6">
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Nama {{ label }}</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.name }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Kode {{ label }}</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.code }}
                <span v-if="office.data.attributes.is_promoted"
                  >({{ office.data.attributes.promoted_by }})</span
                >
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">No. Hp</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.phone ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Email</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.email ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Provinsi</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  officeAddress
                    ? officeAddress.attributes.province_name ?? '-'
                    : '-'
                }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Kabupaten</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  officeAddress
                    ? officeAddress.attributes.city_name ?? '-'
                    : '-'
                }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Kecamatan</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  officeAddress
                    ? officeAddress.attributes.district_name ?? '-'
                    : '-'
                }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Desa</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{
                  officeAddress
                    ? officeAddress.attributes.village_name ?? '-'
                    : '-'
                }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Alamat Stockist 1</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.address ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Alamat Stockist 2</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.address2 ?? '-' }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Wilayah Harga</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ area ? area.attributes.name : '-' }} ({{
                  area ? area.attributes.code : '-'
                }})
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.createdAt | formatDate }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">
                Terakhir Diperbarui
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ office.data.attributes.updatedAt | formatDate }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-gray-700">Suspend</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <toggle-button
                  v-model="office.data.attributes.is_suspended"
                  :labels="{
                    checked: 'Aktifkan',
                    unchecked: 'Suspend',
                  }"
                  :width="office.data.attributes.is_suspended ? 80 : 80"
                  v-on:change="onChangeSuspended"
                />
              </dd>
            </div>
            <div class="sm:col-span-6">
              <dt class="text-sm font-bold text-gray-700">Daftar User</dt>
              <dd class="mt-1 text-sm text-gray-900">
                <datatable :scroll-x="false" :columns="userColumns">
                  <template #tbody="{ classes }">
                    <tr
                      v-for="(user, index) in getIncludedByType(
                        office,
                        'users'
                      )"
                      :key="index"
                      :class="classes.tr"
                    >
                      <td :class="classes.td">{{ user.attributes.name }}</td>
                      <td :class="classes.td">{{ user.attributes.email }}</td>
                      <td :class="classes.td">
                        {{ user.attributes.current_role }}
                      </td>
                    </tr>
                  </template>
                </datatable>
              </dd>
            </div>
          </dl>
        </div>
      </base-card>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    ToggleButton,
  },
  props: {
    visible: Boolean,
    officeType: {
      type: String,
      required: true,
    },
    officeId: String,
  },
  emits: ['close'],
  data() {
    return {
      loading: false,
      office: {
        data: {
          attributes: {},
          relationships: {
            addresses: {
              data: [],
            },
            area: {
              data: null,
            },
            users: {
              data: [],
            },
          },
        },
      },
    };
  },
  computed: {
    area() {
      if (!this.office.data.relationships.area.data) {
        return null;
      }

      return this.getSingleIncludedType('area', this.office, this.office.data);
    },
    label() {
      return {
        stockist: 'Stockist',
      }[this.officeType];
    },
    officeAddress() {
      if (!this.office.data.relationships.addresses.data.length) {
        return null;
      }

      return this.getIncludedByType(this.office, 'addresses')[0];
    },
    userColumns() {
      return [
        { id: 'name', name: 'Nama' },
        { id: 'email', name: 'Email' },
        { id: 'role', name: 'Role' },
      ];
    },
  },
  methods: {
    async onChangeSuspended() {
      this.loading = true;

      await this.request(`/api/v1/offices/${this.officeId}`, {
        method: 'patch',
        data: {
          data: {
            type: 'offices',
            id: this.office.data.id,
            attributes: {
              is_suspended: this.office.data.attributes.is_suspended,
            },
          },
        },
      });

      this.loading = false;
    },
    async onOpened() {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/offices/${this.officeId}`,
        {
          params: {
            include: 'addresses,area,users',
            'fields[offices]':
              'name,code,is_promoted,promoted_by,phone,email,addresses,address,address2,area,createdAt,updatedAt,is_suspended,users',
            'fields[areas]': 'name,code',
            'fields[users]': 'name,email,current_role',
          },
        }
      );

      if (!err) {
        this.office = res;
      }

      this.loading = false;
    },
  },
};
</script>
