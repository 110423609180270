var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-input',{attrs:{"shadow":false,"type":"date"},on:{"change":_vm.loadMemberActivationNotifications},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}}),_c('base-select',{attrs:{"expand":false,"shadow":false,"options":[
        { key: 'all', value: null, label: 'Status' },
        { key: 'dispatched', value: 'dispatched', label: 'Menunggu Antrian' },
        { key: 'created', value: 'created', label: 'Diproses' },
        { key: 'sent', value: 'sent', label: 'Terkirim' },
        { key: 'delivered', value: 'delivered', label: 'Diterima' },
        { key: 'read', value: 'read', label: 'Dibaca' },
        { key: 'failed', value: 'failed', label: 'Gagal' }
      ]},on:{"change":_vm.loadMemberActivationNotifications},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1),_c('datatable',{attrs:{"total":_vm.memberActivationNotifications.meta.page.total,"perPage":_vm.memberActivationNotifications.meta.page.perPage,"currentPage":_vm.memberActivationNotifications.meta.page.currentPage,"columns":[
        { id: 'date', name: 'Tanggal' },
        { id: 'phone', name: 'No Telp' },
        { id: 'status', name: 'Status' }
      ],"scroll-x":false},on:{"pagechanged":_vm.onChangePage},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.memberActivationNotifications.data),function(memberActivationNotification){return _c('tr',{key:memberActivationNotification.id,class:[classes.tr, 'bg-white']},[_c('td',{class:classes.td},[_vm._v(_vm._s(_vm._f("formatDate")(memberActivationNotification.attributes.createdAt)))]),_c('td',{class:classes.td},[_vm._v(_vm._s(memberActivationNotification.attributes.phone))]),_c('td',{class:classes.td},[_c('base-badge',{attrs:{"color":_vm.getStatusBadge(memberActivationNotification.attributes.status).color,"title":memberActivationNotification.attributes.status === 'failed' ? memberActivationNotification.attributes.failed_message : ''}},[_vm._v(_vm._s(_vm.getStatusBadge(memberActivationNotification.attributes.status).text))])],1)])})}}])})],1),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }