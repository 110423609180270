<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-input :shadow="false" type="date" v-model="filter.date" @change="loadMemberActivationNotifications" />
        <base-select :expand="false" :shadow="false" :options="[
          { key: 'all', value: null, label: 'Status' },
          { key: 'dispatched', value: 'dispatched', label: 'Menunggu Antrian' },
          { key: 'created', value: 'created', label: 'Diproses' },
          { key: 'sent', value: 'sent', label: 'Terkirim' },
          { key: 'delivered', value: 'delivered', label: 'Diterima' },
          { key: 'read', value: 'read', label: 'Dibaca' },
          { key: 'failed', value: 'failed', label: 'Gagal' }
        ]" v-model="filter.status" @change="loadMemberActivationNotifications"/>
      </div>
      <datatable
        :total="memberActivationNotifications.meta.page.total"
        :perPage="memberActivationNotifications.meta.page.perPage"
        :currentPage="memberActivationNotifications.meta.page.currentPage"
        :columns="[
          { id: 'date', name: 'Tanggal' },
          { id: 'phone', name: 'No Telp' },
          { id: 'status', name: 'Status' }
        ]"
        :scroll-x="false"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr v-for="memberActivationNotification in memberActivationNotifications.data" :key="memberActivationNotification.id" :class="[classes.tr, 'bg-white']">
            <td :class="classes.td">{{ memberActivationNotification.attributes.createdAt | formatDate }}</td>
            <td :class="classes.td">{{ memberActivationNotification.attributes.phone }}</td>
            <td :class="classes.td">
              <base-badge :color="getStatusBadge(memberActivationNotification.attributes.status).color" :title="memberActivationNotification.attributes.status === 'failed' ? memberActivationNotification.attributes.failed_message : ''">{{ getStatusBadge(memberActivationNotification.attributes.status).text }}</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  data() {
    return {
      filter: {
        date: new Date,
        status: null
      },
      loading: false,
      memberActivationNotifications: {
        data: [],
        meta: {
          page: {}
        }
      }
    }
  },
  methods: {
    getStatusBadge(status) {
      return {
        dispatched: {
          color: 'yellow',
          text: 'Menunggu Antrian'
        },
        created: {
          color: 'indigo',
          text: 'Diproses'
        },
        sent: {
          color: 'green',
          text: 'Terkirim'
        },
        delivered: {
          color: 'purple',
          text: 'Diterima'
        },
        read: {
          color: 'blue',
          text: 'Dibaca'
        },
        failed: {
          color: 'red',
          text: 'Gagal'
        }
      }[status]
    },
    async loadMemberActivationNotifications(params) {
      this.loading = true

      const [res, err] = await this.request('/api/v1/member-activation-notifications', {
        params: {
          'filter[date]': this.filter.date,
          'filter[status]': this.filter.status,
          'page[size]': 5,
          ...params
        }
      })

      if (!err) {
        this.memberActivationNotifications = res
      }

      this.loading = false
    },
    onChangePage(page) {
      this.loadMemberActivationNotifications({
        'page[number]': page
      })
    }
  },
  created() {
    this.loadMemberActivationNotifications()
  }
}
</script>