<template>
  <div class="mb-5 rounded-lg bg-white py-4">
    <div class="space-y-4 divide-y px-4">
      <div class="py-2">
        <div class="mt-4 flex sm:flex-row sm:gap-x-4">
          <base-input
            label="Kode Barang"
            with-label
            inset
            id="kode_barang"
            fullwidth
            :invalid="errors.kode_barang"
            :message="errors.kode_barang"
            required
          >
            <button
              type="button"
              @click="openModal"
              id="open_barangList"
              class="absolute inset-y-0 top-4 right-4 flex cursor-pointer items-center pl-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-500 hover:text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <input
              v-model="value.kode_barang"
              ref="focusNamaBarang"
              @blur="searchProduct"
              type="text"
              name="kode_barang"
              id="kode_barang"
              class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Masukkan Kode Barang"
            />
          </base-input>
          <base-input
            type="text"
            label="Nama Barang"
            placeholder="Masukkan Nama Barang"
            id="nama_barang"
            with-label
            inset
            fullwidth
            :invalid="errors.nama_barang"
            :message="errors.nama_barang"
            v-model="value.nama_barang"
            required
          />
        </div>
        <div class="mt-4 flex sm:flex-row sm:gap-x-4">
          <base-input
            label="Kode Supplier"
            with-label
            inset
            id="supplier_kode_supplier"
            fullwidth
            :invalid="errors.supplier.kode_supplier"
            :message="errors.supplier.kode_supplier"
            required
          >
            <button
              type="button"
              tabindex="-1"
              @click="openModalSupplier"
              id="open_supplierList"
              class="absolute inset-y-0 top-4 right-4 flex cursor-pointer items-center pl-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-500 hover:text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <input
              v-model="value.supplier.kode_supplier"
              @blur="searchSupplier"
              type="text"
              name="supplier_kode_supplier"
              id="supplier_kode_supplier"
              class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Masukkan Kode Supplier"
            />
          </base-input>
          <base-input
            type="text"
            label="Nama Supplier"
            placeholder="Masukkan Nama Supplier"
            id="supplier_nama_supplier"
            with-label
            inset
            fullwidth
            :invalid="errors.supplier.nama_supplier"
            :message="errors.supplier.nama_supplier"
            v-model="value.supplier.nama_supplier"
            required
          />
        </div>

        <div class="mt-4 flex sm:flex-row sm:gap-x-4">
          <base-input
            label="Kelompok Produk"
            with-label
            inset
            fullwidth
            :invalid="errors.product_category.id"
            :message="errors.product_category.id"
            required
          >
            <select
              v-model="value.product_category.id"
              id="product_category_id"
              class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Masukkan Stok Barang"
            >
              <option value="">Pilih Produk Kategori</option>
              <option
                v-for="category in getProductCategories.data"
                :key="category.id"
                :value="category.id"
              >
                {{ category.attributes.name }}
              </option>
            </select>
          </base-input>
          <base-input
            label="Unit"
            with-label
            inset
            fullwidth
            :invalid="errors.unit.id"
            :message="errors.unit.id"
            required
          >
            <select
              v-model="value.unit.id"
              id="unit_id"
              class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Masukkan Stok Barang"
            >
              <option value="">Pilih Unit</option>
              <option
                v-for="unit in getUnits.data"
                :key="unit.id"
                :value="unit.id"
              >
                {{ unit.attributes.name }}
              </option>
            </select>
          </base-input>
        </div>
        <div class="mt-4 flex sm:flex-row sm:gap-x-4">
          <base-input
            type="text"
            label="Volume"
            placeholder="Masukkan Volume Barang"
            id="volume"
            with-label
            inset
            fullwidth
            :invalid="errors.volume"
            :message="errors.volume"
            v-model="value.volume"
            currency
            required
          />
          <base-input
            type="text"
            label="Berat (Kg)"
            placeholder="Masukkan Berat Barang"
            id="berat"
            with-label
            inset
            fullwidth
            :invalid="errors.berat"
            :message="errors.berat"
            v-model="value.berat"
            currency
            required
          />
        </div>
        <div class="mt-4 grid grid-cols-2 sm:gap-x-4">
          <base-input
            type="text"
            class="col-span-1"
            label="Harga Supplier"
            placeholder="Masukkan Harga Supplier"
            id="supplier_price"
            with-label
            inset
            fullwidth
            :invalid="errors.supplier_price"
            :message="errors.supplier_price"
            v-model="value.supplier_price"
            currency
            required
          />
          <base-input
            type="text"
            class="col-span-1"
            label="Harga Supplier Promo"
            placeholder="Masukkan Harga Supplier Promo"
            id="supplier_free_price"
            with-label
            inset
            fullwidth
            :invalid="errors.supplier_free_price"
            :message="errors.supplier_free_price"
            v-model="value.supplier_free_price"
            currency
            required
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from '@/components/base/BaseInput.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductInformationForm',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isSupplierLoading: 'suppliers/getLoading',
      isProductLoading: 'products/getLoading',
      getProducts: 'products/getProducts',
      getUnits: 'units/getUnits',
      getSuppliers: 'suppliers/getSuppliers',
      getProductCategories: 'product_categories/getProductCategories',
      getError: 'products/getError',
    }),
  },
  methods: {
    searchSupplier(value) {
      this.$emit('search-supplier', value);
    },
    searchProduct(value) {
      this.$emit('search-product', value);
    },
    openModal(value) {
      this.$emit('open-modal', value);
    },
    openModalSupplier(value) {
      this.$emit('open-modal-supplier', value);
    },
  },
};
</script>
