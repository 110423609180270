<template>
  <div>
    <Modal
      :showing="visible"
      @close="onClose"
      :showClose="visible"
      :backgroundClose="true"
      size="max-w-6xl"
    >
      <form @submit.prevent="onSubmitForm" id="addOfficeForm">
        <div>
          <div
            class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
          >
            <div>
              <h3 class="text-lg font-semibold leading-6 text-gray-900">
                Tambah Supplier
              </h3>
            </div>
          </div>
          <div class="py-2 px-6">
            <div class="mt-4 flex sm:flex-row sm:gap-x-4">
              <div class="field-inset-default relative w-full">
                <label
                  for="kode_supplier"
                  class="block text-xs font-bold text-gray-700"
                >
                  Kode Supplier
                </label>
                <button
                  type="button"
                  @click="onOpenKantorPelayananModal"
                  class="absolute inset-y-0 top-4 right-4 flex cursor-pointer items-center pl-3"
                >
                  <Icon
                    icon="heroicons:magnifying-glass-circle-20-solid"
                    class="h-5 w-5 text-gray-500 hover:text-gray-700"
                  />
                </button>
                <input
                  v-model="form.kode_kp"
                  ref="focusNamaBarang"
                  @blur="onSearchKantorPelayanan"
                  type="text"
                  name="kode_supplier"
                  id="kode_supplier"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Kode Supplier"
                />
              </div>
              <div class="field-inset-default w-full">
                <label for="name" class="block text-xs font-bold text-gray-700">
                  Nama Supplier
                </label>
                <input
                  v-model="form.nama_kp"
                  typeref="focusNamaKantorPelayanan"
                  type="text"
                  name="name"
                  id="name"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Nama Supplier"
                />
              </div>
              <div class="field-inset-default w-full">
                <label for="area" class="block text-xs font-bold text-gray-700">
                  Area Wilayah
                </label>
                <select
                  v-model="form.area"
                  id="area"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Pilih area wilayah Supplier"
                >
                  <option
                    :value="{
                      id: '',
                      name: '',
                    }"
                  >
                    Pilih Area
                  </option>
                  <option
                    v-for="area in getAreas.data"
                    :key="area.id"
                    :value="{
                      id: area.id,
                      name: area.attributes.name,
                    }"
                  >
                    {{ area.attributes.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mt-4 flex sm:flex-row sm:gap-x-4">
              <div class="field-inset-default w-full">
                <label
                  for="no_hp"
                  class="block text-xs font-bold text-gray-700"
                >
                  No.Hp
                </label>
                <input
                  v-model="form.phone"
                  type="text"
                  name="no_hp"
                  id="no_hp"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan nomor handphone pemilik Supplier"
                />
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="email"
                  class="block text-xs font-bold text-gray-700"
                >
                  Email
                </label>
                <input
                  v-model="form.email"
                  typeref="focusNamaKantorPelayanan"
                  type="text"
                  name="email"
                  id="email"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan email Supplier"
                />
              </div>
            </div>
            <div class="mt-4 flex sm:flex-row sm:gap-x-4">
              <base-input
                label="Pendidikan Terakhir"
                inset
                with-label
                fullwidth
              >
                <base-select
                  :options="selectEducationOptions"
                  id="education_id"
                  inset
                  v-model="form.education_id"
                  expand
                />
              </base-input>
              <base-input label="Pekerjaan Terakhir" inset with-label fullwidth>
                <base-select
                  :options="selectProfessionOptions"
                  id="profession_id"
                  inset
                  v-model="form.profession_id"
                  expand
                />
              </base-input>
            </div>
          </div>
        </div>
        <div>
          <div
            class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
          >
            <div>
              <h3 class="text-lg font-semibold leading-6 text-gray-900">
                Informasi Alamat
              </h3>
            </div>
          </div>
          <div class="py-2 px-6">
            <div class="mt-4 flex sm:flex-row sm:gap-x-4">
              <div class="field-inset-default w-full">
                <label
                  for="provinsi"
                  class="block text-xs font-bold text-gray-700"
                >
                  Provinsi
                </label>
                <select
                  v-model="form.provinsi"
                  @change="onChangeProvince"
                  id="provinsi"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Stok Barang"
                >
                  <option
                    :value="{
                      id: '',
                      name: '',
                    }"
                  >
                    Pilih Provinsi
                  </option>
                  <option
                    v-for="provinsi in getProvinces.data"
                    :key="provinsi.id"
                    :value="{
                      id: provinsi.id,
                      name: provinsi.attributes.name,
                    }"
                  >
                    {{ provinsi.attributes.name }}
                  </option>
                </select>
              </div>
              <div class="field-inset-default w-full">
                <label
                  for="kabupaten"
                  class="block text-xs font-bold text-gray-700"
                >
                  Kabupaten
                </label>
                <select
                  v-model="form.kota"
                  @change="onChangeCity"
                  id="kabupaten"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Stok Barang"
                >
                  <option
                    :value="{
                      id: '',
                      name: '',
                    }"
                  >
                    Pilih Kabupaten
                  </option>
                  <option
                    v-for="kota in getCities.data"
                    :key="kota.id"
                    :value="{
                      id: kota.id,
                      name: kota.attributes.name,
                    }"
                  >
                    {{ kota.attributes.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mt-4 flex sm:flex-row sm:gap-x-4">
              <div class="field-inset-default w-full">
                <label
                  for="kecamatan"
                  class="block text-xs font-bold text-gray-700"
                >
                  Kecamatan
                </label>
                <select
                  v-model="form.kecamatan"
                  @change="onChangeDistrict"
                  id="kecamatan"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Stok Barang"
                >
                  <option
                    :value="{
                      id: '',
                      name: '',
                    }"
                  >
                    Pilih Kecamatan
                  </option>
                  <option
                    v-for="kecamatan in getDistricts.data"
                    :key="kecamatan.id"
                    :value="{
                      id: kecamatan.id,
                      name: kecamatan.attributes.name,
                    }"
                  >
                    {{ kecamatan.attributes.name }}
                  </option>
                </select>
              </div>
              <div class="field-inset-default w-full">
                <label for="desa" class="block text-xs font-bold text-gray-700">
                  Desa
                </label>
                <select
                  v-model="form.desa"
                  id="desa"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Stok Barang"
                >
                  <option
                    :value="{
                      id: '',
                      name: '',
                    }"
                  >
                    Pilih Desa
                  </option>
                  <option
                    v-for="desa in getVillages.data"
                    :key="desa.id"
                    :value="{
                      id: desa.id,
                      name: desa.attributes.name,
                    }"
                  >
                    {{ desa.attributes.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mt-4 flex sm:flex-row sm:gap-x-4">
              <div class="field-inset-default w-full">
                <label
                  for="alamat"
                  class="block text-xs font-bold text-gray-700"
                >
                  Alamat Suplier
                </label>
                <input
                  v-model="form.alamat"
                  type="text"
                  name="alamat"
                  id="alamat"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Masukkan Alamat Supplier"
                />
              </div>
            </div>
            <div class="field-inset-default mt-4 w-full">
              <label
                for="no_account_gl"
                class="block text-xs font-bold text-gray-700"
              >
                No Akun GL
              </label>
              <input
                v-model="form.no_account_gl"
                type="number"
                name="no_account_gl"
                id="no_account_gl"
                class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder=""
              />
            </div>
            <div class="mt-4 flex sm:flex-row sm:gap-x-4">
              <div class="field-inset-default w-full">
                <label
                  for="cost_center"
                  class="block text-xs font-bold text-gray-700"
                >
                  Cost Center
                </label>
                <input
                  v-model="form.cost_center"
                  type="text"
                  name="cost_center"
                  id="cost_center"
                  class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5">
          <div class="flex justify-end">
            <base-button id="simpan_supplier" type="submit">
              Simpan
            </base-button>
            <base-button color="white" type="button" @click="onClose">
              Tutup
            </base-button>
          </div>
        </div>
      </form>
    </Modal>

    <Modal
      :showing="visibleKantorPelayananModal"
      @close="visibleKantorPelayananModal = false"
      :showClose="visibleKantorPelayananModal"
      :backgroundClose="true"
      size="max-w-6xl"
    >
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Daftar Supplier
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Berdasarkan data barang yang telah dibuat
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" class="w-52" />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="flex justify-end">
          <base-search
            v-model="searchKantorPelayananModal"
            @input="onSearchKantorPelayananModal"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="kantorPelayananModalList.meta.page.total"
              :perPage="kantorPelayananModalList.meta.page.perPage"
              :currentPage="kantorPelayananModalList.meta.page.currentPage"
              @pagechanged="onPageChangeKantorPelayananModal"
              :meta="kantorPelayananModalList.meta"
              cursor
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Supplier
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Supplier
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      No. Telp
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Alamat Supplier
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in kantorPelayananModalList.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="onClickKantorPelayananModal(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        getSingleIncluded(
                          kantorPelayananModalList,
                          data.relationships.office.data.id
                        ).attributes.phone
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        getSingleIncluded(
                          kantorPelayananModalList,
                          data.relationships.office.data.id
                        ).attributes.email
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        getSingleIncluded(
                          kantorPelayananModalList,
                          data.relationships.office.data.id
                        ).attributes.address
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <base-button
          @click="visibleKantorPelayananModal = !visibleKantorPelayananModal"
          type="button"
          color="white"
        >
          Tutup
        </base-button>
      </div>
    </Modal>

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
              >
                <svg
                  class="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading"> Data Berhasil disimpan </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="grid grid-cols-2 gap-2">
                <base-button fullwidth @click.prevent="onAddMore">
                  Tambah Lagi
                </base-button>
                <base-button fullwidth color="white" @click="onClose">
                  Selesai
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="failed"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading">
                  {{ getError }}
                </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="grid grid-cols-2 gap-2">
                <button
                  @click.prevent="onAddMore"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Ulangi
                </button>
                <button
                  @click="onClose"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm"
                >
                  Selesai
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <loading v-if="loading.visible" :text="loading.text" />
  </div>
</template>

<script>
import Modal from '@/components/base/BaseModal';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import Datatable from '@/components/base/Datatable';
import ApiService from '@/services/api.service';
import { debounce } from 'debounce';
import { mapActions, mapGetters } from 'vuex';
import BaseSearch from '@/components/base/Search.vue';

export default {
  components: {
    Modal,
    BaseInput,
    BaseButton,
    BaseSelect,
    Datatable,
    BaseSearch,
  },
  props: {
    visible: Boolean,
  },
  emits: ['close', 'success'],
  watch: {
    visible(value) {
      if (value) {
        this.fetchProvinces({
          pageNumber: 1,
        });
        this.fetchEducations({
          fields: {
            educations: 'name',
          },
        });
        this.fetchProfessions({
          fields: {
            professions: 'name',
          },
        });
        this.fetchAreas({
          pageNumber: 1,
          pageSize: 5,
        });
        this.onClearForm();
      }
    },
  },
  data() {
    return {
      loading: {
        visible: false,
        text: 'Loading',
      },
      success: false,
      failed: false,
      visibleKantorPelayananModal: false,
      searchKantorPelayananModal: '',
      kantorPelayananModalList: {
        data: [],
        meta: {
          page: {
            total: null,
          },
        },
      },
      form: {
        id: '',
        kode_kp: '',
        nama_kp: '',
        phone: '',
        email: '',
        alamat: '',
        cost_center: 0,
        area: {
          id: '',
          name: '',
        },
        no_account_gl: 0,
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
        },
        kecamatan: {
          id: '',
          name: '',
        },
        desa: {
          id: '',
          name: '',
        },
        education_id: null,
        profession_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
      getError: 'kantor_pelayanan/getError',
      getAreas: 'areas/getAreas',
      getEducations: 'educations/getEducations',
      getProfessions: 'professions/getProfessions',
      getAvailableOfficeCategories:
        'office_categories/getAvailableOfficeCategories',
    }),
    officeCategoryKantorPelayanan() {
      return this.getAvailableOfficeCategories.find(
        (category) => category.attributes.name === 'Supplier'
      );
    },
    selectEducationOptions: function () {
      return [
        {
          key: 'all',
          label: 'Pilih Pendidikan',
          value: null,
        },
        ...this.getEducations.data.map((education) => ({
          key: education.id,
          label: education.attributes.name,
          value: education.id,
        })),
      ];
    },
    selectProfessionOptions: function () {
      return [
        {
          key: 'all',
          label: 'Pilih Pekerjaan',
          value: null,
        },
        ...this.getProfessions.data.map((profession) => ({
          key: profession.id,
          label: profession.attributes.name,
          value: profession.id,
        })),
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
      fetchAreas: 'areas/fetchAreas',
      fetchEducations: 'educations/fetchEducations',
      fetchProfessions: 'professions/fetchProfessions',
      postKantorPelayanan: 'kantor_pelayanan/createKantorPelayanan',
      patchKantorPelayanan: 'offices/updateOffice',
      patchAddress: 'addresses/updateAddress',
    }),
    onClose() {
      this.success = false;
      this.failed = false;

      this.$emit('close');
    },
    async onSubmitForm() {
      this.startLoading('Menyimpan Data');

      const payload = {
        data: {
          type: 'offices',
          attributes: {
            office_type: 'supplier',
            code: this.form.kode_kp,
            name: this.form.nama_kp,
            pasif: null,
            urut: null,
            address: this.form.alamat,
            address2: null,
            kta: null,
            phone: this.form.phone,
            email: this.form.email,
            pulau: null,
            cost_center: parseInt(this.form.cost_center),
            no_account_gl: parseInt(this.form.no_account_gl),
            address_details: [
              {
                province_id: parseInt(this.form.provinsi.id),
                city_id: parseInt(this.form.kota.id),
                district_id: parseInt(this.form.kecamatan.id),
                village_id: parseInt(this.form.desa.id),
              },
            ],
          },
          relationships: {
            'office-category': {
              data: {
                type: 'office-categories',
                id: this.officeCategoryKantorPelayanan.id,
              },
            },
            area: {
              data: {
                type: 'areas',
                id: this.form.area.id,
              },
            },
            education: this.form.education_id
              ? {
                  data: {
                    type: 'educations',
                    id: this.form.education_id,
                  },
                }
              : undefined,
            profession: this.form.profession_id
              ? {
                  data: {
                    type: 'professions',
                    id: this.form.profession_id,
                  },
                }
              : undefined,
          },
        },
      };

      if (!this.form.id) {
        if (await this.createKantorPelayanan(payload)) {
          this.success = true;
          this.$emit('success');
        } else {
          this.failed = true;
        }
      } else {
        payload.data.id = this.form.id;

        if (await this.updateKantorPelayanan(payload)) {
          this.success = true;
          this.$emit('success');
        } else {
          this.failed = true;
        }
      }

      this.stopLoading();
    },
    onOpenKantorPelayananModal() {
      this.visibleKantorPelayananModal = true;
      this.searchKantorPelayananModal = '';

      this.loadKantorPelayananModalList();
    },
    async onSearchKantorPelayanan() {
      this.startLoading('Mencari Data');

      if (this.form.kode_kp) {
        try {
          const res = await ApiService.get(
            `api/v1/office-categories/${this.officeCategoryKantorPelayanan.id}/offices`,
            {
              params: {
                'filter[code]': this.form.kode_kp,
                'pagination[type]': 'cursor',
                'page[limit]': 1,
                include: 'addresses,area',
                'fields[offices]':
                  'code,name,phone,email,address,addresses,area',
              },
            }
          );

          if (!res.data.data.length) {
            throw new Error();
          }

          const office = res.data.data[0];

          this.form.id = office.id;
          this.form.kode_kp = office.attributes.code;
          this.form.nama_kp = office.attributes.name;
          this.form.phone = office.attributes.phone;
          this.form.email = office.attributes.email;
          this.form.alamat = office.attributes.address;

          const address = this.getIncludedByType(res.data, 'addresses')[0];
          const area = this.getIncludedByType(res.data, 'areas')[0];

          this.form.provinsi.id = address.attributes.province_id;
          this.form.provinsi.name = address.attributes.province_name;
          this.onChangeProvince();

          this.form.kota.id = address.attributes.city_id;
          this.form.kota.name = address.attributes.city_name;
          this.onChangeCity();

          this.form.kecamatan.id = address.attributes.district_id ?? '';
          this.form.kecamatan.name = address.attributes.district_name ?? '';
          this.onChangeDistrict();

          this.form.area.id = area ? area.id : null
          this.form.area.name = area ? area.attributes.name : null

          this.form.desa.id = address.attributes.village_id ?? '';
          this.form.desa.name = address.attributes.village_name ?? '';
        } catch (err) {
          this.form = {
            id: '',
            kode_kp: this.form.kode_kp,
            nama_kp: '',
            phone: '',
            email: '',
            alamat: '',
            cost_center: 0,
            users: {
              data: [],
              meta: {
                page: {
                  total: '',
                  perPage: '',
                  currentPage: '',
                },
              },
            },
            area: {
              id: '',
              name: '',
            },
            no_account_gl: 0,
            provinsi: {
              id: '',
              name: '',
            },
            kota: {
              id: '',
              name: '',
            },
            kecamatan: {
              id: '',
              name: '',
            },
            desa: {
              id: '',
              name: '',
            },
            education_id: null,
            profession_id: null,
          };

          throw err
        } finally {
          this.stopLoading();
        }
      }
    },
    onChangeProvince() {
      if (this.form.provinsi.id) {
        this.fetchCities({
          province_id: this.form.provinsi.id,
          pageNumber: 1,
        });
      }

      this.form.kota = {
        id: '',
        name: '',
      };
      this.form.kecamatan = {
        id: '',
        name: '',
      };
      this.form.desa = {
        id: '',
        name: '',
      };
    },
    onChangeCity() {
      if (this.form.kota.id) {
        this.fetchDistricts({
          city_id: this.form.kota.id,
          pageNumber: 1,
        });
      }

      this.form.kecamatan = {
        id: '',
        name: '',
      };
      this.form.desa = {
        id: '',
        name: '',
      };
    },
    onChangeDistrict() {
      if (this.form.kecamatan.id) {
        this.fetchVillages({
          district_id: this.form.kecamatan.id,
          pageNumber: 1,
        });
      }

      this.form.desa = {
        id: '',
        name: '',
      };
    },
    onClearForm() {
      this.form = {
        id: '',
        kode_kp: '',
        nama_kp: '',
        phone: '',
        email: '',
        alamat: '',
        cost_center: 0,
        area: {
          id: '',
          name: '',
        },
        no_account_gl: 0,
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
        },
        kecamatan: {
          id: '',
          name: '',
        },
        desa: {
          id: '',
          name: '',
        },
        education_id: null,
        profession_id: null,
      };
    },
    onSearchKantorPelayananModal: debounce(function () {
      this.loadKantorPelayananModalList();
    }, 600),
    onPageChangeKantorPelayananModal(page) {
      this.loadKantorPelayananModalList(page);
    },
    async onClickKantorPelayananModal(kantorPelayanan) {
      this.form.kode_kp = kantorPelayanan.attributes.code;

      this.onSearchKantorPelayanan();

      this.searchKantorPelayananModal = '';
      this.visibleKantorPelayananModal = false;
    },
    onAddMore() {
      this.success = false;
      this.failed = false;

      this.onClearForm();
    },

    async createKantorPelayanan(payload) {
      return await this.postKantorPelayanan(payload);
    },
    async updateKantorPelayanan(payload) {
      const office = await this.patchKantorPelayanan(payload);

      if (!office) {
        return false;
      }

      return await this.updateAddress(office);
    },
    async updateAddress(office) {
      const payload = {
        data: {
          type: 'addresses',
          id: office.data.data.relationships['addresses'].data[0].id,
          attributes: {},
          relationships: {
            office: {
              data: {
                type: 'offices',
                id: this.form.id + '',
              },
            },
            province: {
              data: {
                type: 'provinces',
                id: this.form.provinsi.id + '',
              },
            },
            city: {
              data: {
                type: 'cities',
                id: this.form.kota.id + '',
              },
            },
            district: {
              data: {
                type: 'districts',
                id: this.form.kecamatan.id + '',
              },
            },
            village: {
              data: {
                type: 'villages',
                id: this.form.desa.id + '',
              },
            },
          },
        },
      };

      return await this.patchAddress(payload);
    },
    async loadKantorPelayananModalList(params = {}) {
      this.startLoading('Mencari Data');

      try {
        const response = await ApiService.get(`api/v1/offices`, {
          params: {
            ...params,
            'filter[office_category_id]': 6,
            'page[limit]': 5,
            include: 'office',
            'fields[simple-offices]': 'code,name,office',
            'field[offices]': 'phone,email,address',
            'filter[search]': this.searchKantorPelayananModal,
          },
        });

        this.kantorPelayananModalList = response.data;
      } catch (err) {
        //
      } finally {
        this.stopLoading();
      }
    },
    startLoading(text) {
      this.loading.text = text;
      this.loading.visible = true;
    },
    stopLoading() {
      this.loading.text = 'Loading';
      this.loading.visible = false;
    },
  },
};
</script>
