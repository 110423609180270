<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-lg"
      @close="$emit('close')"
      @opened="onOpened"
    >
      <base-card
        title="Aktivasi P3S"
        :with-style="false"
        description="Lengkapi form dibawah ini untuk aktivasi fitur P3S"
      >
        <form class="space-y-4 border-t pt-5" @submit.prevent="onSubmit">
          <search-office-input :with-modal="false" v-model="form.office" />
          <base-input
            fullwidth
            inset
            type="text"
            label="Nominal Pinjaman"
            placeholder="Masukkan nominal pinjaman (Contoh 10.000.000)"
            currency
            v-model="form.amount"
          />
          <div class="grid grid-cols-2 gap-2">
            <base-button :disabled="disabled" fullwidth>Aktivasi</base-button>
            <base-button
              type="button"
              fullwidth
              color="light"
              @click="$emit('close')"
              >Tutup</base-button
            >
          </div>
        </form>
      </base-card>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseModal from '@/components/base/BaseModal.vue';
import { mapActions } from 'vuex';
import SearchOfficeInput from './search-office-input.vue';

export default {
  mixins: [requestMixin],
  components: { BaseModal, SearchOfficeInput },
  props: {
    visible: Boolean,
    officeId: String,
  },
  emits: ['close', 'pbs-activated'],
  data() {
    return {
      form: {
        office: {
          id: null,
          code: null,
          searchCode: null,
          originalCode: null,
          name: null,
        },
        amount: null,
      },
      loading: false,
    };
  },
  computed: {
    disabled() {
      return !this.form.office.id || !this.form.amount;
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    onOpened() {
      this.form.office.id = null;
      this.form.office.code = null;
      this.form.office.searchCode = null;
      this.form.office.originalCode = null;
      this.form.office.name = null;

      this.form.amount = null;
    },
    async onSubmit() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/offices/${this.officeId}/-actions/activate-pbs`,
        {
          method: 'post',
          data: {
            lender_office_id: this.form.office.id,
            amount: Number(this.form.amount.replace(/\D/g, '')),
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.$emit('pbs-activated');
      }

      this.loading = false;
    },
  },
};
</script>
