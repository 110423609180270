var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex justify-end"},[_c('base-input',{attrs:{"type":"search","placeholder":"Cari Stockist","shadow":false,"debounce":""},on:{"native-input":_vm.loadOffices},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('datatable',{attrs:{"scroll-x":false,"columns":_vm.tableColumns,"total":_vm.offices.meta.page.total,"perPage":_vm.offices.meta.page.perPage,"currentPage":_vm.offices.meta.page.currentPage,"meta":_vm.offices.meta,"cursor":""},on:{"pagechanged":_vm.onPageChanged},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.offices.data),function(office,index){return _c('tr',{key:index,class:[classes.tr, 'bg-white hover:bg-green-100']},[_c('td',{class:[classes.td, 'cursor-pointer space-y-1'],on:{"click":function($event){_vm.onViewOffice(_vm.getOfficeIncluded(office))}}},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(office.attributes.code)+" ")]),_c('p',{staticClass:"text-xs text-gray-700"},[_vm._v(_vm._s(office.attributes.name))])]),_c('td',{class:[classes.td, 'cursor-pointer'],on:{"click":function($event){_vm.onViewOffice(_vm.getOfficeIncluded(office))}}},[_vm._v(" "+_vm._s(_vm.getOfficeIncluded(office).attributes.area_code)+" ")]),_c('td',{class:[classes.td, 'cursor-pointer'],on:{"click":function($event){_vm.onViewOffice(_vm.getOfficeIncluded(office))}}},[_vm._v(" "+_vm._s(_vm.getOfficeIncluded(office).attributes.phone ?? '-')+" ")]),_c('td',{class:[classes.td, 'cursor-pointer'],on:{"click":function($event){_vm.onViewOffice(_vm.getOfficeIncluded(office))}}},[_vm._v(" "+_vm._s(_vm.getOfficeIncluded(office).attributes.address ?? '-')+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{class:_vm.getOfficeIncluded(office).attributes.is_promoted
                  ? ''
                  : 'cursor-pointer',attrs:{"color":_vm.getOfficeIncluded(office).attributes.is_promoted
                  ? 'indigo'
                  : 'green'},on:{"click":function($event){_vm.onPromote(_vm.getOfficeIncluded(office))}}},[_vm._v(" "+_vm._s(_vm.getOfficeIncluded(office).attributes.is_promoted ? _vm.getOfficeIncluded(office).attributes.promoted_by : 'Promosi ke SC')+" ")])],1),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{class:_vm.getOfficeIncluded(office).attributes.is_pbs_activated
                  ? ''
                  : 'cursor-pointer',attrs:{"color":_vm.getOfficeIncluded(office).attributes.is_pbs_activated
                  ? 'indigo'
                  : 'green'},on:{"click":function($event){_vm.onActivatePbs(_vm.getOfficeIncluded(office))}}},[_vm._v(" "+_vm._s(_vm.getOfficeIncluded(office).attributes.is_pbs_activated ? 'Aktif' : 'Aktivasi P3S')+" ")])],1)])})}}])})],1),_c('view-office-modal',{attrs:{"office-type":"stockist","visible":_vm.viewModal.visible,"office-id":_vm.viewModal.officeId},on:{"close":function($event){_vm.viewModal.visible = false}}}),_c('promote-office-to-sc-modal',{attrs:{"visible":_vm.promoteToScModal.visible,"office-id":_vm.promoteToScModal.officeId},on:{"close":function($event){_vm.promoteToScModal.visible = false},"promoted":_vm.onPromoted}}),_c('activate-office-pbs-modal',{attrs:{"visible":_vm.activatePbsModal.visible,"office-id":_vm.activatePbsModal.officeId},on:{"close":function($event){_vm.activatePbsModal.visible = false},"pbs-activated":_vm.onPbsActivated}}),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }